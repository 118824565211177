@font-face {
  font-family: blackout-midnight;
  src: url("blackout_midnight-webfont.8a6e5172.eot#iefix") format("embedded-opentype"), url("blackout_midnight-webfont.f7f05d2a.woff") format("woff"), url("blackout_midnight-webfont.ca00aa5b.ttf") format("truetype"), url("blackout_midnight-webfont.bbf804e1.svg#ciclefina") format("svg");
}

body {
  font-family: Segoe UI, Helvetica, Calibri, Sans Serif;
}

.promotion {
  margin-top: 1rem;
}

.acetheexam {
  font-weight: bold;
  text-decoration: underline;
}

.content {
  height: 100%;
  min-height: calc(100vh - 3em);
  margin-top: 1rem;
  margin-bottom: 2em;
}

.results {
  margin-bottom: 2em;
}

.footer .row {
  width: 100%;
}

h1 {
  margin-bottom: 1em;
  padding: 10px;
  font-family: blackout-midnight, Arial Black;
}

input.search {
  margin-bottom: 20px;
}

#smart-suggestion-heading, #text-suggestion-heading {
  visibility: hidden;
}

.date-group div {
  margin-top: 3px;
}

#starting-date-input, .input-date-length {
  width: 200px;
  margin: auto;
}

.well-sm {
  padding: 5px;
}

.popover-content ul {
  margin-left: 1em;
  padding-left: 0;
}

.glyphicon.spinning {
  animation: 2s linear infinite spin;
  -webkit-animation: 2s linear infinite spin2;
}

@keyframes spin {
  from {
    transform: scale(1)rotate(0);
  }

  to {
    transform: scale(1)rotate(360deg);
  }
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.c700d5d1.css.map */
