@font-face {
    font-family: 'blackout-midnight';
    src: url('./fonts/blackout/blackout_midnight-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/blackout/blackout_midnight-webfont.woff') format('woff'),
        url('./fonts/blackout/blackout_midnight-webfont.ttf') format('truetype'),
        url('./fonts/blackout/blackout_midnight-webfont.svg#ciclefina') format('svg');
}

body {
    font-family: "Segoe UI", Helvetica, Calibri, Sans Serif;
}

.promotion {
    margin-top: 1rem;
}

.acetheexam {
    font-weight: bold;
    text-decoration: underline;
}

.content {
    min-height: calc(100vh - 3em);
    height: 100%;
    margin-top: 1rem;
    margin-bottom: 2em;
}

.results {
    margin-bottom: 2em;
}

.footer .row {
    width: 100%;
}

h1 {
    margin-bottom: 1em;
    padding: 10px;
    font-family: 'blackout-midnight', "Arial Black";
}

input.search {
    margin-bottom: 20px;
}

#smart-suggestion-heading,
#text-suggestion-heading {
    visibility: hidden;
}

.date-group div {
    margin-top: 3px;
}

#starting-date-input {
    width: 200px;
    margin: auto;
}

.input-date-length {
    width: 200px;
    margin: auto;
}

.well-sm {
    padding: 5px;
}


.popover-content ul {
    margin-left: 1em;
    padding-left: 0;
}

.glyphicon.spinning {
    animation: spin 2s infinite linear;
    -webkit-animation: spin2 2s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}